.voice-commands-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  width: 100%;
  position: relative;
}

.voice-controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.message-container {
  position: absolute;
  right: calc(50% + 5rem);
  top: 50%;
  transform: translateY(-50%);
  padding: 1rem;
  border-radius: 0.5rem;
  width: max-content;
  max-width: 300px;
}

.message-error {
  background-color: #fee2e2;
  color: #a41817;
}

.message-success {
  background-color: #dcfce7;
  color: #166534;
}

.voice-button, .help-button {
  padding: 1rem;
  border-radius: 9999px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  color: white;
  transition: background-color 0.2s;
  border: none;
  cursor: pointer;
}

.help-button {
  background-color: #a41817;
}

.help-button:hover {
  background-color: #520c14;
}

.voice-button-listening {
  background-color: #79c117;
}

.voice-button-listening:hover {
  background-color: #5c9412;
}

.voice-button-idle {
  background-color: #a41817;
}

.voice-button-idle:hover {
  background-color: #520c14;
}

.mic-icon, .help-icon {
  height: 1.5rem;
  width: 1.5rem;
}

.mic-icon-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.help-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1500;
}

.help-modal {
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 600px;
  width: 90%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}

.help-modal h2 {
  margin: 0 0 1.5rem;
  color: #a41817;
  font-size: 1.5rem;
  font-weight: 600;
}

.commands-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.command-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem;
  background-color: #f3f4f6;
  border-radius: 0.375rem;
  gap: 1rem;
}

.command-phrase {
  color: #a41817;
  font-weight: 500;
  font-family: monospace;
}

.command-description {
  color: #4b5563;
  text-align: right;
}

.close-button {
  margin-top: 1.5rem;
  padding: 0.5rem 1rem;
  background-color: #a41817;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.close-button:hover {
  background-color: #520c14;
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}
