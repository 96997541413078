.dashboard-content {
  min-height: calc(100vh - 168px); /* header height is 168px */
  position: relative;
}

/* Only add padding when voice commands are present */
.dashboard-content:has(.voice-commands-wrapper) {
  padding-bottom: 4rem;
}

.voice-commands-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f3f4f6;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: center;
  padding: 0.5rem;
  z-index: 50;
  height: 4rem;
}
