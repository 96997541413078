$red: #a41817;
$darkred: #520c14;

.tablesorter-scroller-bar-spacer {
  background: #eee;
}
/* add border to right side (LTR pages) of fixed column */
.tablesorter-scroller-fixed:after {
  content: "";
  border-right: 1px solid #444;
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 2;
  /* set to zero for non-jquery ui themes; use "left" here for RTL pages */
  right: 0;
  /* match the margins set to the table to keep the border the same height as the table */
  margin: 10px 0 15px;
}

.tablesorter-scroller-table {
  overflow-x: hidden !important;
}

#imgLogo {
  height: 160px;
}
.LoginBanner {
  background: $red; /* Old browsers */
  background: -moz-linear-gradient(top, $red 0%, $darkred 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, $red),
    color-stop(100%, $darkred)
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    $red 0%,
    $darkred 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    $red 0%,
    $darkred 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, $red 0%, $darkred 100%); /* IE10+ */
  background: linear-gradient(to bottom, $red 0%, $darkred 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a41817', endColorstr="#520c14",GradientType=0 ); /* IE6-9 */
  text-align: center;
}

.LoginHeader {
  color: #7b2623;
  font-family: "PT Sans", sans-serif;
  text-align: center;
  display: block;
  font-size: 24pt;
  font-weight: 700;
  margin-top: 20px;
}

.LoginParagraph {
  text-align: center;
  width: 600px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  font-size: 18pt;
  line-height: 1.1;
}

.LoginContainer {
  text-align: center;
}

.Auth0Button {
  font-size: 1.5em;
  color: white;
  background-color: $red;
}

#LoginBox {
  color: white;
  font-family: "PT Sans", sans-serif;
  background-color: $red;
  width: 200px;
  height: 100px;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}

#LoginBox input[type="text"],
#LoginBox input[type="password"] {
  width: 100%;
  margin-top: 3px;
  background-color: white;
}

#LoginBox input[type="submit"] {
  background-color: #84b741;
  border: none;
  color: white;
  margin-top: 2px;
  float: right;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
}

#LoginBox a {
  clear: both;
  float: left;
  color: white;
  text-decoration: none;
}

.invalid {
  border: 1px solid red;
}

.skin_header {
  display: none;
}

.content02 {
  width: 100% !important;
}
.main_center {
  margin: 0px !important;
}

.MatrixBottomButtons {
  float: right;
  margin-top: 10px;
}

/* Add extra margin only when voice commands are present */
.dashboard-content:has(.voice-commands-wrapper) {
  margin-bottom: 5rem;
}

.MatrixBottomButtons input,
.MatrixBottomButtons a,
.SlideTable tr td a {
  color: white !important;
  background-color: #a01a17;
  padding: 10px;
  border: none;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
  margin: 0 10px;
}
.SlideTable tr td {
  color: #444444;
  border-bottom: 1px solid #a01a17;
  padding: 5px;
}
.SlideTable tr td a {
  display: block;
  width: 125px;
  text-align: center;
}

.feed_title {
  font-size: 60px;
  line-height: 60px;
  margin-top: 30px;
  letter-spacing: -1px;
  margin-bottom: 40px;
  margin-left: 10px;
  font-weight: bold;
  color: #000;
}

.feed_text {
  font-size: 40px;
  line-height: 50px;
  height: 400px;
  overflow: hidden;
  width: 690px;
  float: right;
  padding-right: 130px;
  color: #ffffff;
}

.feed_image {
  padding: 15px 15px 15px 15px;
  float: left;
  width: 400px;
  height: auto;
}

#MatrixTable .channel-name {
  border-bottom: 1px dotted white;
}

#MatrixTable thead tr th,
table tfoot tr th {
  background-color: #a01a17;
  color: #ffffff;
  border: 1px solid #a01a17;
  font-size: 8pt;
  padding: 4px;
  padding-top: 4px;
  padding-bottom: 4px;
}

#MatrixTable thead tr th {
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 15px;
  text-align: center;
}

#MatrixTable td {
  color: #3d3d3d;
  padding: 4px;
  background-color: #fff;
  vertical-align: top;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
}

#MatrixTable {
  border-collapse: collapse;
}
#MatrixTable th,
#MatrixTable td {
  margin: 0px;
}

#MatrixTable th p {
  display: inline-block;
  margin: 0px;
  line-height: 32px;
}

#MatrixTable th .material-icons {
  vertical-align: middle;
  cursor: pointer;
  font-size: 32px;
  line-height: 32px;
}

#MatrixTable td .material-icons {
  vertical-align: middle;
  font-size: 24px;
  line-height: 24px;
  color: #a01a17;
}

.material-icons {
  vertical-align: middle;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.clickable {
  cursor: pointer;
}

.icon-disabled {
  opacity: 0.33;
}

.button_green {
  background-color: #79c117;
}
.largebutton {
  padding: 10px;
  font-family: "PT Sans", sans-serif;
  font-size: 18px;
}

.button_yellow,
.button_lightred,
.button_red,
.button_green,
.button_darkgrey,
.controlpanel_playerrefresh {
  border: none;
  color: White !important;
  cursor: pointer;
  text-decoration: none;
  padding: 10px;
  display: inline-block;
  font-size: 16px;
  margin: 0px 4px;
}

.button_lightred {
  background-color: #9e1c17;
}
#matrixtopbuttons {
  display: inline-block;
  width: 100%;
  text-align: right;
}

.SlideTable tr td a {
  display: block;
  width: 125px;
  text-align: center;
}

a {
  text-decoration: none;
}

.controlpanel_topheader,
.addslide_header,
.matrix_header {
  margin-top: 20px;
  color: #a21917;
  font-size: 24px;
  font-weight: bold;
}

.addslidesectiontopbar,
.controlpanelsectiontopbar {
  background-color: white;
  color: #a01a17;
  border-bottom: 1px solid #a21917;
  height: 34px;
  width: 979px;
  margin-top: 10px;
  padding: 2px;
  font-size: 18px;
  font-family: Trebuchet MS;
}

.addslidesection,
.controlpanelsection {
  background-color: white;
  border: none;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
}

.addslidesection.schedule {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 10px;

  grid-template-areas:
    "header header header"
    "main main main"
    "main main main";
}

.addslidesection .note {
  color: grey;
  font-style: italic;
  grid-area: header;
}

.addslidesection .dates {
  grid-area: main;
}

.addslidesection.schedule .main {
  grid-area: main;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  column-gap: 10px;
  row-gap: 10px;
}

.react-datepicker-popper {
  z-index: 22;
}

.columns-2 {
  grid-template-columns: repeat(2, fit-content(100%));
}

.Template {
  border: 3px solid transparent;
}
.TemplateSelected {
  border: 3px solid #87ba41;
}

.VeMediaMenu {
  background: #a41817;
  background: -moz-linear-gradient(top, #a41817 0%, #520c14 100%);
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, #a41817),
    color-stop(100%, #520c14)
  );
  background: -webkit-linear-gradient(top, #a41817 0%, #520c14 100%);
  background: -o-linear-gradient(top, #a41817 0%, #520c14 100%);
  background: -ms-linear-gradient(top, #a41817 0%, #520c14 100%);
  background: linear-gradient(to bottom, #a41817 0%, #520c14 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a41817', endColorstr='#520c14',GradientType=0 );
  position: relative;
  line-height: 1.5;
  padding-left: 20px;
}

.MenuRight {
  position: absolute;
  top: 8px;
  right: 10px;
}

.MenuBottom {
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  color: white !important;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.VeMediaMenu a {
  font-family: "PT Sans", sans-serif;
  font-size: 20px;
  color: white !important;
  text-decoration: none;
}

.GreenLink {
  background-color: #79c117;
  padding: 10px;
}

.button_red {
  background-color: #571719;
}
.button_lightred,
.controlpanel_playerrefresh {
  background-color: #9e1c17;
}

/* GRIDS */

@mixin grid-fit($columns) {
  display: grid !important;
  display: -ms-grid !important;
  grid-template-columns: repeat($columns, max-content);
  -ms-grid-columns: unquote("(#{max-content})[#{$columns}]");
  * {
    @include ie-grid-items($items: 200, $columns: $columns);
  }
}

@mixin grid-fr($columns) {
  display: grid !important;
  display: -ms-grid !important;
  grid-template-columns: repeat($columns, 1fr);
  -ms-grid-columns: unquote("(#{1fr})[#{$columns}]");

  * {
    @include ie-grid-items($items: 200, $columns: $columns);
  }
}

@mixin ie-grid-items($start: 1, $items: 50, $columns: 3) {
  $row: 1;
  $column: 0;
  @for $i from $start through $items {
    // 20% 30px 20% 30px 20% 30px 20% = column layout for IE$column: $column + 1;  // skip columns for space between (30px columns)

    $column: $column + 1;

    &:nth-child(#{$i}) {
      -ms-grid-row: $row;
      -ms-grid-column: $column;
      display: block;
    } // Every fourth item we reset the columns and increment the row.
    @if $i % $columns == 0 {
      $column: 0;
      $row: $row + 1;
    }
  }
}

@for $i from 1 through 10 {
  .grid-fit-#{$i} {
    @include grid-fit($columns: $i);
  }
}

@for $i from 1 through 10 {
  .grid-fr-#{$i} {
    @include grid-fr($columns: $i);
  }
}

.grid-1211 {
  display: grid !important;
  display: -ms-grid !important;
  grid-template-columns: 1fr 2fr 1fr 1fr;
  -ms-grid-columns: 1fr 2fr 1fr 1fr;

  * {
    @include ie-grid-items($items: 200, $columns: 4);
  }
}

.grid-311 {
  display: grid !important;
  display: -ms-grid !important;
  grid-template-columns: 3fr 1fr 1fr;
  -ms-grid-columns: 3fr 1fr 1fr;

  * {
    @include ie-grid-items($items: 200, $columns: 3);
  }
}

.webmaster-grid-header {
  background-color: #9e2836;
  color: white;
  font-weight: bold;
}

.controlpanel_header {
  background-color: #571719;
  color: White;
  display: block;
  font-size: 16px;
  padding-left: 6px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
}

.loader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #9e2836;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1000;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rssoverall {
  display: block !important;
}

/* content library */

.header_out {
  background-color: #575757;
  width: 100%;
  position: fixed;
  z-index: 1;
}
.header_in {
  margin: 0px auto;
}

.menu_category ul {
  display: none;
}

.menu_category:hover ul {
  display: block;
}

.contentlibrary_menu li {
  display: inline-block;
  padding: 10px;
  transition: background-color 0.5s;
}

.contentlibrary_menu li:hover {
  background-color: #222;
}
.contentlibrary_menu a,
.contentlibrary_menu span {
  color: white;
}

.contentlibrary_menu .menu_subcategories {
  position: absolute;
  margin-top: 10px;
  list-style: none;
  padding: 10px 5px;
  background: #222;
}

.contentlibrary_menu .menu_subcategories li {
  display: block;
}

.cl-buttons div {
  background-color: #1abbf0;
  padding: 5px 8px;
  border: 0px solid #fff;
  height: auto;
  display: inline-block !important;
  margin-right: 5px;
}

.cl-buttons a {
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 15px;
  display: block;
}

.cl-pagination-link {
  display: inline-block;
  margin-right: 10px;
  font-size: 18px;
  cursor: pointer;
  text-transform: uppercase;
}

.cl-slideshow {
  position: relative;
  margin: auto;
  height: 493px;
  overflow: hidden;
}

/* Hide the images by default */
.cl-slide {
  display: inline-block;
  position: absolute;
  transition: left 1s;
}

.cl-slide.active {
  display: inline-block;
}
